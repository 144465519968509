import { EventParticipantsCard, ManagementSecMainHeader } from 'components'
import React, { lazy, Suspense, useEffect, useState } from 'react'

import { getEligibleEventParticipants, } from 'apis/events'
import { useParams } from 'react-router-dom'
import { decodeFromBase64 } from 'utils/encodeAndDecode'
import { sendNotifications } from 'apis/notification'
import { toast } from 'react-toastify'

const EventEligibleParticipantsNotification = lazy(() => import("components/NEW/popups").then(module => ({ default: module.EventEligibleParticipantsNotification })));



function Index() {

    const { id } = useParams()
    const [participants, setParticipants] = useState([])
    const [selectedParticipants, setSelectedParticipants] = useState([])
    const [showNotificationPopup, setShowNotificationPopup] = useState(false)
    const [pageNation, setPageNation] = useState({
        current_page: 1,
        next_page: null,
    })


    const fetchEligibleEventParticipants = async (page) => {
        const res = await getEligibleEventParticipants(decodeFromBase64(id), page)
        setParticipants(prev => ([...prev, ...(res?.data || [])]));
        setPageNation({ ...res?.pagination })
    }

    const handleSendNotificationToParticipants = async (value) => {
        toast.loading('sending notification')
        const data = {
            ...value,
            type: "promotional",
            users: selectedParticipants,
            all_users: false
        }
        const res = await sendNotifications(data, false)
        toast.dismiss()
        if (res?.success) {
            toast.success('Sended notification successfully')
            setSelectedParticipants([])
        }

    }

    const handleSelectParticipants = (id) => {
        if (selectedParticipants.includes(id)) {
            setSelectedParticipants(selectedParticipants.filter(participant => participant !== id));
        } else {
            setSelectedParticipants([...selectedParticipants, id]);
        }
    }


    useEffect(() => {
        fetchEligibleEventParticipants(1)
    }, [])

    return (
        <div className='flex flex-col h-screen'>
            {showNotificationPopup &&
                <div className="fixed inset-0 z-50 flex items-center justify-center h-full bg-black bg-opacity-50">
                    <div className="relative w-[40%] max-h-full p-6 overflow-auto bg-white rounded-lg">
                        <Suspense fallback={<div>Loading...</div>}>
                            <EventEligibleParticipantsNotification handleCancel={() => setShowNotificationPopup(false)} handleSubmit={handleSendNotificationToParticipants} />
                        </Suspense>
                    </div>
                </div>
            }

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Event"}
                        subHeading={"Manage your events Effectively"}
                        btnText={"Eligible Participants"}
                        handleBtn={() => setShowNotificationPopup(true)}
                    />

                    <div className="flex flex-wrap gap-5">
                        {participants?.map((item, index) => (
                            <div className={`w-[250px] my-5 ${selectedParticipants.includes(item?.id) && 'border-2 shadow-xl shadow-blue-300'}`} key={item?.id} onClick={() => handleSelectParticipants(item?.id)}>
                                <EventParticipantsCard
                                    serialNo={index + 1}
                                    data={item}
                                    isVerified={item?.verification?.status === 'verified' ? true : false}
                                />
                            </div>
                        ))}
                    </div>
                    {pageNation.next_page &&
                        <div className="flex items-center justify-center my-10 text-blue-500 cursor-pointer" onClick={() => fetchEligibleEventParticipants(pageNation.next_page)}>
                            see more
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default Index