import { ManagementSecMainHeader, Popup, Table } from "components"
import React, { useEffect, useState } from 'react'

import { getAllAccountDeletedUsers, updateUserAccountStatus } from 'apis/users/index.js'
import { USERS } from 'common/routes'
import { formatDateToDDMMYY } from 'shared/dates.js'
import { encodeToBase64 } from 'utils/encodeAndDecode'
import verification from 'assets/icons/verified.png'
import { toast } from "react-toastify"

function Index() {

    const [userData, setUserData] = useState()
    const [accountStatusPopup, setAccountStatusPopup] = useState({ id: false, status: false })
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [currentTab, setCurrentTab] = useState('requested')

    const columns = [

        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        {
            Header: 'Name',
            accessor: 'user.name',
            Cell: ({ row }) => (
                <div>
                    <button className="text-blue-500 cursor-pointer hover:underline" onClick={() => handleView(row)}>{row?.original?.user?.name}</button>
                </div>
            )
        },
        {
            Header: 'Photo',
            accessor: 'user.photo',
            Cell: ({ value }) => (
                <img src={value} alt="userPhoto" className='w-[66px] h-[70px]' />
            )
        },
        {
            Header: 'Email',
            accessor: 'email',
            Cell: ({ row }) => (
                <div className='flex items-center'>
                    <p>{row.original.user?.email}</p>
                    {row.original.user?.verification?.status === 'verified' && (
                        <img src={verification} alt="Verified" className='ms-2 w-[20px] h-[20px]' />
                    )}
                </div>
            )
        },
        { Header: 'Phone', accessor: 'user.phone' },
        { Header: 'createdAt', accessor: (row) => formatDateToDDMMYY(row.createdAt) },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <>
                    {value === 'requested' ?
                        <span className='p-2 text-green-500 bg-green-100 rounded-3xl'>
                            requested
                        </span>
                        :
                        <span className='p-2 text-red-500 bg-red-100 rounded-3xl'>
                            {value}
                        </span>

                    }
                </>

            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => {
                const status = row.original.status;
                return (
                    <div>
                        {status === 'requested' ? (
                            <>
                                <button onClick={() => setAccountStatusPopup({ id: row.original.id, status: 'cancelled' })} className='p-2 px-3 mr-1 text-white bg-green-500 rounded-xl'>Cancel</button>
                                <button onClick={() => setAccountStatusPopup({ id: row.original.id, status: 'deleted' })} className='p-2 px-3 mr-1 text-white bg-red-500 rounded-xl'>Delete</button>
                            </>
                        ) : status === 'cancelled' ? (
                            <button className='bg-[#8bbbf8] opacity-[40%] text-white p-2 px-3 rounded-xl' disabled>Cancelled</button>
                        ) : status === 'deleted' ? (
                            <button className='bg-brandRed opacity-[40%] text-white p-2 px-3 rounded-xl' disabled>Deleted</button>
                        ) : null}
                    </div>
                );
            }
        }
    ];


    const handleView = (row) => {
        const id = encodeToBase64(row.original.userId)
        const url = USERS.EDIT_USER_DETAILS.replace(':id', id);
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const fetchAllAccountDeletedUsers = async (page, status = 'requested', rowsPerPage = 10) => {
        setUserData(null)
        const res = await getAllAccountDeletedUsers(page, status, rowsPerPage)
        setUserData(res?.data)
        const data = res?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }

    const updateUserAccount = async (data) => {
        toast.loading('Updating user Account Status Please Wait...')
        const res = await updateUserAccountStatus(data?.id, { status: data?.status })
        if (res?.success) {
            fetchAllAccountDeletedUsers(1, currentTab)
            setAccountStatusPopup(prev => ({ ...prev, id: false }))
        }
        toast.dismiss()
    }

    useEffect(() => {
        fetchAllAccountDeletedUsers(1, currentTab, rowsPerPage)
    }, [currentTab])



    return (
        <div className='flex flex-col h-screen'>

            {accountStatusPopup.id &&
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <Popup text={'Are you sure ?'} onClose={() => setAccountStatusPopup(prev => ({ ...prev, id: false }))} onConfirm={() => updateUserAccount(accountStatusPopup)} />
                </div>
            }


            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Create User"}
                        subHeading={"user Management"}
                        btnText={"Add User"}
                    />
                </div>
                {/* Table */}
                <div className="p-8">
                    <div className='flex justify-between'>
                        <div className="flex">
                            <button onClick={() => setCurrentTab('requested')} className={`p-2 px-6 border ${currentTab === 'requested' && 'bg-gray-300'}`}>Requested</button>
                            <button onClick={() => setCurrentTab('deleted')} className={`p-2 px-6 border-2 border-b-0 ${currentTab === 'deleted' && 'bg-gray-300'}`}>Deleted</button>
                            <button onClick={() => setCurrentTab('cancelled')} className={`p-2 px-6 border-2 border-b-0 ${currentTab === 'cancelled' && 'bg-gray-300'}`}>Cancelled</button>
                        </div>

                        <div className="flex items-center">
                            <label htmlFor="rowsPerPage" className="block text-sm font-medium text-gray-700">
                                Rows per page:
                            </label>
                            <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                value={rowsPerPage}
                                onChange={(e) => {
                                    setRowsPerPage(e.target.value)
                                    fetchAllAccountDeletedUsers(pageNation.currentPage, currentTab, e.target.value)
                                }}
                                className="block p-2 px-6 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">

                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={500}>500</option>
                            </select>
                        </div>
                    </div>
                    {userData &&
                        <Table columns={columns} data={userData} pageNation={pageNation} rowsPerPage={rowsPerPage} handlePageNation={(value) => fetchAllAccountDeletedUsers(value, userFilter)} />
                    }
                </div>
            </div>
        </div>
    )
}

export default Index