import { Steps } from 'components';
import React, { useEffect, useState } from 'react';
import { UpdateBlog } from 'apis/blog';
import { BLOG } from 'common/routes';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Index({ saveData, onSaveSuccess, onSaveFailed, id, blogData, isEdit }) {
    const navigate = useNavigate()

    const [data, setData] = useState({
        slug: blogData?.slug_url || null,
        focusKeys: blogData?.focus_keys || [],
        CanonicalUrl: blogData?.canonical_url || null,
        tags: blogData?.tags || [],
        altText: blogData?.blog_content_image_alts || [],
    })


    const handleSaveData = async () => {
        const apiData = {
            current_index: 6,
            id,
            slug: data?.slug,
            focusKeys: data?.focusKeys,
            CanonicalUrl: data?.CanonicalUrl,
            tags: data?.tags,
            altText: data?.altText,
            isEdit: isEdit ? true : false
        }
        const toastId = toast.loading('Creating blog')
        const res = await UpdateBlog(apiData)
        toast.dismiss(toastId)
        if (res?.success) {
            onSaveSuccess(res?.data)
            navigate(BLOG.BLOG)
        } else {
            onSaveFailed()
        }
    }

    useEffect(() => {
        if (saveData) handleSaveData()
    }, [saveData])


    const handleAddTag = (event) => {
        const { name, value } = event.target;
        if (event.key === 'Enter' && value.trim() !== '') {
            event.preventDefault();
            setData(prevData => ({
                ...prevData,
                [name]: [...prevData[name], value.trim()],
            }));
            event.target.value = ''; // Clear input after adding tag
        }
    };

    // Function to handle removing a tag or focus key
    const handleRemoveTag = (name, indexToRemove) => {
        setData(prevData => ({
            ...prevData,
            [name]: prevData[name].filter((_, index) => index !== indexToRemove),
        }));
    };

    return (
        <div className="w-full p-6 mx-auto text-black bg-white rounded-lg shadow-lg">
            {/* Progress Bar */}
            <div className="flex items-center mb-8">
                <Steps number={1} isActive={true} lineActive={true} />
                <Steps number={2} isActive={true} lineActive={true} />
                <Steps number={3} isActive={true} lineActive={true} />
                <Steps number={4} isActive={true} lineActive={true} />
                <Steps number={5} isActive={true} lineActive={true} />
                <Steps number={6} isActive={true} lineActive={false} displayLine={false} />
            </div>

            <div className="mb-6">
                <label className="block mb-2 text-base font-semibold">URL (Slug)</label>
                <input value={data?.slug}
                    onChange={(e) => setData(prev => ({ ...prev, slug: e.target.value }))} type="text"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>

            {/* Focus Keys Input */}
            <div className="mb-6">
                <label className="block mb-2 text-base font-semibold">Focus Keys</label>
                <div className="flex flex-wrap gap-2 p-2 bg-gray-100 rounded-lg">
                    {data.focusKeys.map((key, index) => (
                        <div key={index} className="flex items-center space-x-2 px-3 py-1 bg-gray-200 rounded-full text-sm">
                            <span>{key}</span>
                            <button type="button" onClick={() => handleRemoveTag('focusKeys', index)} className="text-gray-500 hover:text-gray-700">
                                &times;
                            </button>
                        </div>
                    ))}
                    <input type="text" name="focusKeys" onKeyDown={handleAddTag}
                        className="flex-grow p-2 border-none focus:ring-0"
                        placeholder="Type and press Enter"
                    />
                </div>
            </div>

            {/* Tags Input */}
            <div className="mb-6">
                <label className="block mb-2 text-base font-semibold">Tags</label>
                <div className="flex flex-wrap gap-2 p-2 bg-gray-100 rounded-lg">
                    {data.tags.map((tag, index) => (
                        <div key={index} className="flex items-center space-x-2 px-3 py-1 bg-gray-200 rounded-full text-sm" >
                            <span>{tag}</span>
                            <button type="button" onClick={() => handleRemoveTag('tags', index)} className="text-gray-500 hover:text-gray-700">
                                &times;
                            </button>
                        </div>
                    ))}
                    <input type="text" name="tags" onKeyDown={handleAddTag}
                        className="flex-grow p-2 border-none focus:ring-0"
                        placeholder="Type and press Enter"
                    />
                </div>
            </div>


            {/* Alt Text */}
            <div className="mb-6">
                <label className="block mb-2 text-base font-semibold">Alt Text</label>
                <div className="flex flex-wrap gap-2 p-2 bg-gray-100 rounded-lg">
                    {data.altText.map((alt, index) => (
                        <div key={index} className="flex items-center space-x-2 px-3 py-1 bg-gray-200 rounded-full text-sm" >
                            <span>{alt}</span>
                            <button type="button" onClick={() => handleRemoveTag('altText', index)} className="text-gray-500 hover:text-gray-700">
                                &times;
                            </button>
                        </div>
                    ))}
                    <input type="text" name="altText" onKeyDown={handleAddTag}
                        className="flex-grow p-2 border-none focus:ring-0"
                        placeholder="Type and press Enter"
                    />
                </div>
            </div>

            <div className="mb-6">
                <label className="block mb-2 text-base font-semibold">(Canonical URL)</label>
                <input value={data?.CanonicalUrl}
                    onChange={(e) => setData(prev => ({ ...prev, CanonicalUrl: e.target.value }))} type="text"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>

        </div>
    )
}

export default Index
