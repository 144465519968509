import { getAllPlansCategory } from 'apis/plans';
import { DropDown, InputField } from 'components';
import React, { useEffect, useState } from 'react';
import { addLabelAndValueInData } from 'utils/dataConversion';

function Index({ data, handleSubmit, handleCancel, isEdit }) {
  const [planCategory, setPlanCoin] = useState()
  const [coinData, setPlanData] = useState({
    actionName: data?.actionName,
    points: data?.points,
    type: data?.type,
    message: data?.message,
    expiryDays: data?.expiryDays,
    plan_category: data?.plan_category?._id,
    status: data?.status,
    id: data?._id
  })


  const content = [
    { label: 'Coin Name', value: 'actionName', isDropDown: false, isTextArea: false },
    { label: 'Message', value: 'message', isDropDown: false, isTextArea: true },
    { label: 'Points', value: 'points', isDropDown: false, isTextArea: false },
    { label: 'Type', value: 'type', isDropDown: true, isTextArea: false, options: [{ label: 'Debit', value: 'debit' }, { label: 'Credit', value: 'credit' }] },
    { label: 'Plan Category', value: 'plan_category', isDropDown: true, options: planCategory, isTextArea: false },
    { label: 'Expire Date', value: 'expiryDays', isDropDown: false, isTextArea: false },
    { label: 'Status', value: 'status', isDropDown: true, options: [{ label: 'Active', value: true }, { label: 'Inactive', value: false }], isTextArea: false },
  ]

  const updateState = (key, value) => {
    setPlanData(prev => ({ ...prev, [key]: value }))
  }

  const fetchAllPlansCategory = async () => {
    const res = await getAllPlansCategory()
    const categories = addLabelAndValueInData(res?.data, 'name', 'id')
    setPlanCoin(categories)
  }

  useEffect(() => {
    fetchAllPlansCategory()
  }, [])

  return (
    <div className="w-full mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-xl font-bold mb-4">Create Plan name</h2>
      <p className="mb-2">Add time that highlight what makes it unique</p>
      <p className="mb-6">Event Time</p>

      <div className="flex flex-wrap -mx-2">
        {content?.map((item, index) => (
          <div className="w-1/2 px-2 my-6" key={index}>
            <div className='flex items-center justify-between'>
              <label className="text-lg text-gray-700">{item?.label}</label>
              <div className={`w-[450px] h-[56px] -mt-4`}>
                {item?.isDropDown ?
                  <DropDown selectedValue={coinData[item?.value]} handleOnChange={(value) => updateState(item?.value, value)} options={item?.options} />
                  :
                  <InputField value={coinData[item?.value]} onChange={(value) => updateState(item?.value, value)} textArea={item?.isTextArea} readOnly={index === 0 && isEdit ? true : false} />
                }
              </div>
            </div>
          </div>
        ))}
      </div>


      <div className="flex justify-center mt-10">
        <button type="button" className="px-4 py-2 bg-gray-200 text-gray-700 w-[120px] h-[50px]" onClick={handleCancel}>Cancel</button>
        <button type="submit" className="px-4 py-2 bg-brandRed text-white w-[120px] h-[50px]" onClick={() => handleSubmit(coinData)}>Submit</button>
      </div>
    </div >
  );
};

export default Index;