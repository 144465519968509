import { auth } from "apis";

//  =========  Plan Section ========== 
export const getAllPlans = async () => {
    const res = await auth({
        method: "GET",
        url: `/plans`,
    });
    return res;
};

export const getPlansByCategoryId = async ({ id }) => {
    const res = await auth({
        method: "GET",
        url: `/plans/category/${id}`,
    });
    return res;
}

export const getMultipleCategory = async (data) => {
    const res = await auth({
        method: "POST",
        url: `/plans/category/multiple`,
        data
    });
    return res;
}

export const createNewPlan = async (data) => {
    const res = await auth({
        method: "POST",
        url: `/plans`,
        data,
    });
    return res;
}

export const updatePlan = async (id,data) => {
    const res = await auth({
        method: "PUT",
        url: `/plans/${id}`,
        data
    });
    return res;
}

//  =========  Plan Category Section ========== 
export const getAllPlansCategory = async () => {
    const res = await auth({
        method: "GET",
        url: `/plans/categories`,
    });
    return res;
}

export const createNewPlanCategory = async (data) => {
    const res = await auth({
        method: "POST",
        url: `/plans/category`,
        data,
    });
    return res;
}

export const updatePlanCategory = async (id,data) => {
    const res = await auth({
        method: "PUT",
        url: `/plans/category/${id}`,
        data
    });
    return res;
}

//  =========  Plan Limitation Section ========== 
export const getAllPlansLimitations = async (page) => {
    const res = await auth({
        method: "GET",
        url: `/admin/limitations?page=${page}&limit=10`,
    });
    return res;
}

export const createNewPlanLimitation = async (data) => {
    const res = await auth({
        method: "POST",
        url: `/admin/limitations`,
        data,
    });
    return res;
}

export const updatePlanLimitation = async (id, data) => {
    const res = await auth({
        method: "PUT",
        url: `/admin/limitations/${id}`,
        data
    });
    return res;
}

//  =========  Plan Coin Section ========== 
export const getAllPlansCoins = async (page) => {
    const res = await auth({
        method: "GET",
        url: `/admin/actions?page=${page}&limit=10`,
    });
    return res;
}

export const createNewPlanCoin = async (data) => {
    const res = await auth({
        method: "POST",
        url: `/admin/action`,
        data,
    });
    return res;
}

export const updatePlanCoin = async (id, data) => {
    const res = await auth({
        method: "PUT",
        url: `/admin/action?actionId=${id}`,
        data
    });
    return res;
}