import { UpdateBlog } from 'apis/blog'
import { Steps } from 'components'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'


function Index({ saveData, onSaveSuccess, onSaveFailed, id, blogData }) {

    const [data, setData] = useState({
        meta_title: blogData?.meta_title || null,
        meta_description: blogData?.meta_description || null
    })

    const handleSaveData = async () => {
        const toastId = toast.loading('Creating blog')
        const apiData = {
            current_index: 2,
            id,
            meta_title: data.meta_title,
            meta_description: data.meta_description,
        }
        const res = await UpdateBlog(apiData)
        toast.dismiss(toastId)
        if (res?.success) {
            onSaveSuccess(res?.data)
        } else {
            onSaveFailed()
        }
    }

    useEffect(() => {
        if (saveData) handleSaveData()
    }, [saveData])


    return (
        <div className="w-full p-6 mx-auto bg-white rounded-lg shadow-lg">
            {/* Progress Bar */}
            <div className="flex items-center mb-8">
                <Steps number={1} isActive={true} lineActive={true} />
                <Steps number={2} isActive={true} />
                <Steps number={3} />
                <Steps number={4} />
                <Steps number={5} />
                <Steps number={6} displayLine={false} />
            </div>

            {/* Meta Title */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-semibold text-gray-700">Meta Title</label>
                <input type="text" value={data?.meta_title}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={(e) => setData(prev => ({ ...prev, meta_title: e.target.value }))}
                />
            </div>

            {/* Meta Description */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-semibold text-gray-700">Meta Description</label>
                <textarea rows="4" value={data?.meta_description} onChange={(e) => setData(prev => ({ ...prev, meta_description: e.target.value }))}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                ></textarea>
            </div>
        </div>
    )
}

export default Index
