export const getEventBasicDetailsFromLocalBySlug = (slug) => {
    const eventData = JSON.parse(localStorage.getItem('eventData')) || [];
    const event = eventData.find(item => item[slug]);
    return event ? event[slug] : null;   
}

export const deleteEventBySlugFromLocal = (slug) => {
    const eventData = JSON.parse(localStorage.getItem('eventData')) || [];
    const eventIndex = eventData.findIndex(item => item[slug]);
    if (eventIndex !== -1) {
        eventData.splice(eventIndex, 1);
        localStorage.setItem('eventData', JSON.stringify(eventData));
        return true; 
    }
    return false; 
};


export const extractId = (data) => {
    if (Array.isArray(data)) {
        return data.map(item => item._id);
    } else if (data && typeof data === 'object' && data._id) {
        return data._id;
    } else {
        return null;
    }
};

