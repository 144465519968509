import { ManagementSecMainHeader, Table } from "components"
import React, { useEffect, useState } from 'react'

import { NOTIFICATION } from "../../../common/routes.js"

import { useNavigate } from "react-router-dom"
import { getNotificationHistory } from "apis/notification/index.js"
import { formatDateToDDMMYYInNumber } from "shared/dates.js"


function Index() {
    const navigate = useNavigate()
    const [history, setHistory] = useState()
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Title', accessor: 'title' },
        { Header: 'body', accessor: 'body' },
        { Header: 'Created At', accessor: (row) => formatDateToDDMMYYInNumber(row.createdAt) },
        {
            Header: 'Filter',
            accessor: 'filters',
            Cell: ({ value }) => {
                if (!value || !Array.isArray(value)) return '-';
                return value.map((item, index) => (
                    <div key={index}>
                        <strong>Key:</strong> {item.key} <br />
                        <strong>Type:</strong> {item.filterType} <br />
                        <strong>Values:</strong> {item.values.join(', ')},
                    </div>
                ));
            },
        },
        { Header: 'User Count', accessor: 'user_count' },

    ];

    const fetchNotificationHistory = async (page = 1, limit = rowsPerPage) => {
        const res = await getNotificationHistory(['promotional'], page, limit)
        if (res?.success) {
            setHistory(res?.data)
            setPageNation({ currentPage: res?.pagination?.current_page, nextPage: res?.pagination?.next_page, prevPage: res?.pagination?.previous_page })
        }
    }


    useEffect(() => {
        fetchNotificationHistory(1)
    }, [])


    const handleBtn = async () => {
        navigate(NOTIFICATION.NOTIFICATION)
    }


    return (
        <div className='flex flex-col h-screen'>

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Create Notification"}
                        subHeading={"Notification History"}
                        btnText={"Create Notification"}
                        handleBtn={handleBtn}
                    />
                </div>
                {/* Table */}
                <div className="p-8">
                    <div className="flex items-center">

                        <div className="flex items-center">
                            <label htmlFor="rowsPerPage" className="block text-sm font-medium text-gray-700">
                                Rows per page:
                            </label>
                            <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                value={rowsPerPage}
                                onChange={(e) => {
                                    setRowsPerPage(e.target.value)
                                    fetchAllUsers(pageNation.currentPage, null, e.target.value)
                                }}
                                className="block p-2 px-6 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">

                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={500}>500</option>
                            </select>
                        </div>
                    </div>

                    {history ?
                        <Table columns={columns} data={history} pageNation={pageNation} handlePageNation={(value) => fetchAllUsers(value)} rowsPerPage={rowsPerPage} />
                        :
                        <div className="flex items-center justify-center w-full text-2xl text-black">Loading the Data Please Wait</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Index