import { DropDown, InputField } from "components";
import React, { memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { boolean } from "shared/constants";

// Assets
import { editEvent, getEventById } from 'apis/events';
import { decodeFromBase64 } from "utils/encodeAndDecode";
import { useNavigate, useParams } from "react-router-dom";
import { EVENTS } from "common/routes";
import { extractId } from "../actions";


function Index() {
    const navigate = useNavigate()
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { type, id } = useParams();

    const [eventData, setEventData] = useState({
        // Left Side
        enable_comments: false,
        display_attendees: false,
        featured: false,
        publish_status: null,
        google_map_url: null,


        // Right Side
        prebooking_enabled: false,
        prebooking_from: null,
        prebooking_to: null,
        prebooking_slots: null,

        meta_title: null,
        meta_description: null,
        event_connections_opp_gender: true,
    });


    const updateState = (key, value) => {
        setEventData(prev => ({ ...prev, [key]: value }))
    }


    const fetchEventData = async () => {
        const decodedId = decodeFromBase64(id)
        const res = await getEventById(decodedId)
        if (res?.success) {
            setEventData({
                ...res?.data,
                display_attendees: res?.data?.display_attendees,
                enable_comments: res?.data?.enable_comments,
                featured: res?.data?.featured,
                google_map_url: res?.data?.google_map_url,
                meta_description: res?.data?.meta_description,
                meta_title: res?.data?.meta_title,
                prebooking_enabled: res?.data?.prebooking_enabled,
                prebooking_from: res?.data?.prebooking_from,
                prebooking_slots: res?.data?.prebooking_slots,
                prebooking_to: res?.data?.prebooking_to,
                host: extractId(res?.data?.host),
                co_hosts: extractId(res?.data?.co_hosts),
                categories: extractId(res?.data?.categories),
                tags: extractId(res?.data?.tags),
                location: extractId(res?.data?.location),
                event_connections_opp_gender: res?.data?.event_connections_opp_gender,
                // publish_status: res?.data?.publish_status,
            })
        }
    }


    const contentLeftSide = [
        { type: 'dropDown', key: 'enable_comments', label: "Enable Comments", options: boolean },
        { type: 'dropDown', key: 'display_attendees', label: "Display Attendees", options: boolean },
        { type: 'dropDown', key: 'featured', label: "Featured", options: boolean },
        { type: 'text', key: 'meta_title', label: "Meta Title" },

    ]

    const contentRightSide = [
        { type: 'dropDown', key: 'prebooking_enabled', label: "Prebooking Enabled", options: boolean, isVisible: true },
        { type: 'text', key: 'prebooking_from', label: "Prebooking From", isVisible: eventData.prebooking_enabled, inputFiledType: 'date' },
        { type: 'text', key: 'prebooking_to', label: "Prebooking To", isVisible: eventData.prebooking_enabled, inputFiledType: 'date' },
        { type: 'text', key: 'prebooking_slots', label: "Prebooking Slots", isVisible: eventData.prebooking_enabled },
        { type: 'description', key: 'meta_description', label: "Meta Description", isVisible: true, inputFiledType: 'description' },
        { type: 'dropDown', key: 'event_connections_opp_gender', label: "Event Connections Opp Gender", options: boolean, isVisible: true },
    ]

    useEffect(() => {
        if (id) {
            fetchEventData()
        }
    }, [])


    const handleSaveAndContinue = async () => {
        try {
            const eventDataWithStep = {
                ...eventData,
                step: 2
            }
            // eslint-disable-next-line unused-imports/no-unused-vars
            const { enable_connections, sold_out, current_step, createdAt, updatedAt, id, ...other } = eventDataWithStep
            const res = await editEvent(id, other)
            const toastId = toast.loading('Creating Event')
            if (res?.success) navigate(EVENTS.EVENTS)
            toast.dismiss(toastId)
        } catch (error) {
            toast.dismiss()
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    const renderInput = (item) => {
        switch (item?.type) {
            case 'dropDown':
                return (
                    <div>
                        <div className='font-semibold'>{item?.label} </div>
                        <div className="h-[56px] w-[400px] mb-8 flex items-center rounded-3xl">
                            <DropDown
                                selectedValue={eventData?.[item?.key]}
                                placeholderValue={item?.label}
                                options={item?.options}
                                mode={item?.mode}
                                handleOnChange={(value) => updateState(item?.key, value)}
                            />
                        </div>
                    </div>
                )
            case 'text':
                return (
                    <div>
                        <div className='font-semibold'>{item?.label} </div>
                        <div className="h-[56px] w-[400px] mb-8 flex items-center rounded-3xl">
                            <InputField
                                value={eventData?.[item?.key]}
                                placeholder={item?.label}
                                onChange={(value) => updateState(item?.key, value)}
                                type={item?.inputFiledType}
                                className="w-[400px]"
                            />
                        </div>
                    </div>
                )
            case 'description':
                return (
                    <div>
                        <div className='font-semibold'>{item?.label} </div>
                        <div className="w-[400px] mb-8 flex items-center rounded-3xl">
                            <textarea rows="4" cols="50"
                                placeholder={item?.label}
                                onChange={(e) => updateState(item?.key, e.target.value)}
                                value={eventData?.[item?.key]}
                                className="rounded-[2px] text-black border-2 border-gray-300"
                            ></textarea>
                        </div>
                    </div>
                )
        }
    }

    return (
        <div>
            <div className="flex items-start justify-between mt-16 mb-5">
                <h1 className='text-lg font-semibold'>What type of event are you creating</h1>
            </div>
            <div className="flex justify-between">
                <div>
                    {contentLeftSide?.map((item, index) => (
                        <div key={index} className='w-[45%]'>
                            {renderInput(item)}
                        </div>
                    ))}
                </div>

                <div>
                    {contentRightSide?.map((item, index) => (
                        item?.isVisible && (
                            <div key={index} className='w-[45%]'>
                                {renderInput(item)}
                            </div>
                        )
                    ))}
                </div>
            </div>



            <div className="flex items-center justify-center gap-5 w-full mb-10">
                <button onClick={() => handleSaveAndContinue()} className='p-2 px-4 text-white bg-green-500 rounded-2xl'>Save And Continue</button>
            </div>
        </div>
    )
}

export default memo(Index)