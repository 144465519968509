import moment from 'moment';

export const addLabelAndValueInData = (datas,labelKey,valueKey) => {
    return datas?.map(data => ({
        ...data,
        label: data?.[labelKey]?.replace(/[-_]/g, ' '),
        value: data?.[valueKey]
    }));
};


export const formatStringToCapitalist = (input="")=> {  // in_a_relation
    let formatted = input.replace(/[_-]/g, ' ');
    const lowerCaseWords = ['a', 'an', 'the', 'and', 'but', 'or', 'for', 'nor', 'on', 'at', 'to', 'from', 'by'];
    formatted = formatted.split(' ').map((word) => {
        word = word.replace(/([a-z])([A-Z])/g, '$1 $2');
        return word.split(' ').map((w, i) => {
            if (i !== 0 && lowerCaseWords.includes(w.toLowerCase())) {
                return w.toLowerCase();
            }
            return w.charAt(0).toUpperCase() + w.slice(1).toLowerCase();
        }).join(' ');
    }).join(' ');
    return formatted;    // In a Relation
}

export const timeToHrAndMin = (data) =>{   // 2024-03-09T18:30:00.000Z
    const date = new Date(data);
    return date.toISOString().substring(11, 16);  // 18:30
}


export const formatDate = (data) => {    //  2024-05-10T00:00:00.000Z
    return moment(data).format('DD-MM-YYYY');    //  10-05-2024
}

export const formatDateToDisplayDateField = (dateString) => {
    const date = new Date(dateString);
        if (isNaN(date.getTime())) {
        const currentDate = new Date();
        return currentDate.toISOString().split('T')[0];
    }
    return date.toISOString().split('T')[0];
}

export function formatDateToMMdd(dateString) {    // 2024-12-07T10:53:16.701Z
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(2);
    return `${month}/${year}`   // 12/24
}


// export function formatDateTo12HourTime(isoString) {  // 2024-05-18T13:36:01.955Z
//     const date = new Date(isoString);
//     let hours = date.getUTCHours();
//     const minutes = date.getUTCMinutes();
//     const ampm = hours >= 12 ? 'PM' : 'AM';
//     hours = hours % 12;
//     hours = hours ? hours : 12;
//     const strMinutes = minutes < 10 ? '0' + minutes : minutes;
//     const formattedTime = `${hours}:${strMinutes} ${ampm}`;
//     return formattedTime;  // 1:36 PM
// }

export function formatDateTo12HourTime(isoString, useUtc = false) {
    let date;
    if (useUtc) {
      date = moment.utc(isoString);
    } else {
      date = moment(isoString);
    }
    return date.format('h:mm A');
}
  

export function convertTo24HourFormat(time12h) {
    const [time, modifier] = time12h?.split(" ");
    let [hours, minutes] = time?.split(":");
  
    if (modifier === "PM" && hours !== "12") {
      hours = String(parseInt(hours, 10) + 12);
    } else if (modifier === "AM" && hours === "12") {
      hours = "00";
    }
  
    return `${hours}:${minutes}`;
  }
  