import * as Yup from 'yup';


export const eventSlugGeneration = Yup.object({
  start_date: Yup.date()
      .required('Start date is required')
      .typeError('Invalid date format'),
  title: Yup.string()
      .required('Event Title is required'),
  category: Yup.string()
      .required('Category is required'),
});



export const createEventValidationBasic = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  image: Yup.string().optional(),
  // short_description: Yup.string(),
  description: Yup.string().required('Description is required'),
  start_date: Yup.date().required('Start date is required'),
  end_date: Yup.date().required('End date is required'),
  start_time: Yup.string().required('Start time is required'),
  end_time: Yup.string().required('End time is required'),
  price_for_couple: Yup.boolean(),
  isFree: Yup.boolean(),
  price: Yup.number()
    .optional()
    .when('isFree', {
      is: false,
      then: Yup.number().required('Price is required when event is not free'),
    }),
  max_coin_redeemable: Yup.number().optional(),
  total_slots: Yup.number().optional(),
  gender_based_slots: Yup.boolean(),
  slots_for_men: Yup.number().optional(),
  slots_for_female: Yup.number().optional(),
  prebooking_enabled: Yup.boolean(),
  prebooking_from: Yup.date().optional(),
  prebooking_to: Yup.date().optional(),
  prebooking_gender_based_slots: Yup.mixed().optional(),
  prebooking_slots_for_men: Yup.mixed().optional(),
  prebooking_slots_for_female: Yup.mixed().optional(),
  prebooking_slots: Yup.number()
    .optional()
    .when('prebooking_enabled', {
      is: true,
      then: Yup.number().optional(),
    }),
  enable_comments: Yup.boolean(),
  display_attendees: Yup.boolean(),
  featured: Yup.boolean(),
  host: Yup.string()
    .optional()
    .matches(/^[0-9a-fA-F]{24}$/, 'Invalid host ID'),
  co_hosts: Yup.array()
    .of(Yup.string().matches(/^[0-9a-fA-F]{24}$/, 'Invalid co-host ID'))
    .optional(),
  mode: Yup.string().oneOf(['online', 'offline']),
  location: Yup.string()
    .optional()
    .matches(/^[0-9a-fA-F]{24}$/, 'Invalid location ID'),
  tags: Yup.array()
    .of(Yup.string().matches(/^[0-9a-fA-F]{24}$/, 'Invalid tag ID'))
    .optional(),
  categories: Yup.array()
    .of(Yup.string().matches(/^[0-9a-fA-F]{24}$/, 'Invalid category ID'))
    .optional(),
  relationship_status: Yup.string().oneOf(['single', 'couple']).optional(),
  age_from: Yup.number().optional(),
  age_to: Yup.number().optional(),
  marital_status: Yup.array().of(Yup.string()).optional(),
  online_event_link: Yup.string().optional(),
  offline_event_link: Yup.string().optional(),
  lattitude: Yup.string().optional(),
  longitude: Yup.string().optional(),
  google_map_url: Yup.string().optional(),
  slug: Yup.string().optional(),
  meta_title: Yup.string().optional(),
  meta_description: Yup.string().optional(),
  gender: Yup.string()
    .oneOf(['male', 'female', 'unisex'])
    .optional()
    .default('unisex'),
  freemium_plans: Yup.array()
    .of(Yup.string().matches(/^[0-9a-fA-F]{24}$/, 'Invalid freemium plan ID'))
    .optional(),
  looking_for: Yup.array().optional(),
  event_connections_opp_gender: Yup.boolean().optional(),
  step: Yup.number()
    .min(1, 'Step must be at least 1')
    .max(2, 'Step cannot be more than 2')
    .required('Step is required'),
});
