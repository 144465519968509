import languages from "assets/json/languages.json";
import { accountStatus, boolean, couplesLookingForOptionsCouples, couplesMartialStatusOPtions, drinkingOptions, eatingOptions, educationOptions, filterSectionInterests, GENDER, physicalStatusOptions, relationshipStatusOptions, singlesLookingForOptions, singlesMartialStatusOPtions, smokingOptions, timeLineOptions, verificationStatus } from "shared/constants";
import { addLabelAndValueInData } from "utils/dataConversion";

const languagesOptions = addLabelAndValueInData(languages, "name", "name")

export const USERFILTERKEYS = [
    {label: "Age From", key: "age_from", inputType: 'input', },
    {label: "Age To", key:"age_to", inputType: 'input', },
    {label: "Sex", key: "sex", inputType: 'singleDropdown' },
    
    {label:"Relationship Status",key:"relationship_status",inputType:'singleDropdown'},
    {label:"Marital Status",key:"marital_status",inputType:'singleDropdown'},
    {label:"Looking for",key:"looking_for",inputType:'singleDropdown'},
    {label:"Interests",key:"interests",inputType:'singleDropdown'},
    {label:"Timeline",key:"timeline",inputType:'singleDropdown'},
    
    // Religion and Cast
    {label:"Religion",key:"religion_id",inputType:'singleDropdown'},
    {label: "Caste", key: "caste_id", inputType: 'singleDropdown' },
    
    // Physical
    {label:"Physical Status",key:"physical_status",inputType:'singleDropdown'},
    {label:"Height From",key:"height_from",inputType:'input'},
    {label:"Height To",key:"height_to",inputType:'input'},
    {label:"Weight From", key: "weight_from", inputType: 'input' },
    {label:"Weight To", key:'weight_to', inputType: 'input' },
    
    // Diet
    {label:"Eating",key:"eating",inputType:'singleDropdown'},
    { label: "Drinking", key: "drinking", inputType: 'singleDropdown' },
    { label: "Smoking", key: "smoking", inputType: 'singleDropdown' },

    
    {label:"No of Children",key:"no_of_children",inputType:'input'},
    {label:"CTC",key:"ctc",inputType:'input'},
    
    { label: "Mother Tongue", key: "mother_tongue", inputType: 'singleDropdown' },
    { label: "Languages Known", key: "languages_known", inputType: 'singleDropdown' },
    
    {label:"Verification",key:"verification",inputType:'singleDropdown'},
    {label:"Profile Completed",key:"profile_completed",inputType:'singleDropdown'},
    {label:"Account Status",key:"account_blocked",inputType:'singleDropdown'},
    {label:"Onboarding Completed",key:"onboarding_completed",inputType:'singleDropdown'},

    
    {label:"Plans",key:"plans",inputType:'singleDropdown'},
    // {label:"Plan Categories",key:"plan_categories",inputType:'singleDropdown'},

    {label: "Joined From", key: "joined_from", inputType: 'input', },
    {label: "Joined To", key:"joined_to", inputType: 'input', },
]


export const FILTER_OPTIONS_MAP = {
    sex: GENDER,
    relationship_status: relationshipStatusOptions,
    marital_status: [...singlesMartialStatusOPtions,...couplesMartialStatusOPtions],
    looking_for: [...singlesLookingForOptions,...couplesLookingForOptionsCouples],
    interests: filterSectionInterests,
    timeline: timeLineOptions,
    
    religion_id:[],
    caste_id: [],
    
    physical_status:physicalStatusOptions,

    drinking: drinkingOptions,
    smoking: smokingOptions,
    eating: eatingOptions,

    mother_tongue:languagesOptions,
    languages_known:languagesOptions,
    
    education: educationOptions,
    verification: verificationStatus,
    profile_completed: boolean,
    onboarding_completed: boolean,
    account_blocked:accountStatus,

    plans:[],
    // plan_categories:[],

    joined_from:'',
    joined_to:'',
};
