import { auth } from "apis";
import qs from 'qs';

export const sendNotifications = async (data, filter) => {
    let url = '/notifications'
    if (filter) {
        const queryString = qs.stringify(filter, { arrayFormat: 'brackets' });
        url = `/notifications?${queryString}`;
    }
    const res = await auth({
        method: "POST",
        url: url,
        data,
    });
    return res;
}

export const getNotificationHistory = async (type, page, limit, eventId) => {
    const queryParams = { type,page,limit,eventId,}
    const queryString = qs.stringify(queryParams, { arrayFormat: 'brackets', skipNulls: true });

    const res = await auth({
        method: "GET",
        url: `/notifications/history?${queryString}`,
    });

    return res;
};
