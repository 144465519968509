import React from "react";
import Error404 from "pages/404";
import Login from "pages/Login";

// NEW VERSION
// Home
import OverView from 'pages/home/overView';

// Event Management
import CreateAndEditEventBasicDetails from 'pages/eventManagement/createAndEditEvent/basicDetails';
import CreateAndEditEventAdvanceDetails from 'pages/eventManagement/createAndEditEvent/advanceDetails';
import EventCategory from 'pages/eventManagement/eventCategory';
import EventComment from 'pages/eventManagement/eventComment';
import EventConnection from 'pages/eventManagement/eventConnection';
import EventHosts from 'pages/eventManagement/eventHost';
import EventLocation from 'pages/eventManagement/eventLocation';
import EventParticipants from 'pages/eventManagement/eventParticipants';
import EventEligibleParticipants from 'pages/eventManagement/eligibleParticipants';
import EventPreview from 'pages/eventManagement/eventPreview';
import Events from 'pages/eventManagement/events';
import EventTag from 'pages/eventManagement/eventTag';

// User Management 
import CreateUser from 'pages/userManagement/createUser';
import DeletedUsers from 'pages/userManagement/deletedUser';
import EditUserDetails from 'pages/userManagement/editUser';
import ViewAllUsers from 'pages/userManagement/users';

//  Plan and Limitation Management
import PlanCategory from 'pages/planManagement/planCategory';
import PlanCoins from 'pages/planManagement/planCoins';
import PlanLimitation from 'pages/planManagement/planLimitation';
import Plans from 'pages/planManagement/plans';

//  Coupons Management
import Coupons from 'pages/couponsManagement/coupons';

// Notification Management
import Notification from 'pages/notificationManagement/notifications';
import NotificationHistory from 'pages/notificationManagement/history';

// Verification Management
import Verification from 'pages/verificationManagement/verification';

// AppUpdateManagement
import AppUpdate from 'pages/appUpdateManagement/appUpdate';

// Blogs Management
import Blogs from 'pages/blog/blogs';
import CreateBlog from 'pages/blog/createBlog';
import ViewBlog from 'pages/blog/viewBlog';

// Affiliate Coupons Management
import AffiliateCoupons from 'pages/affiliateReferralsManagement';
import TrackAffiliateCoupons from 'pages/affiliateReferralsManagement/trackReferral/index';

// Offer Management
import CreateOffer from 'pages/offerManagement/createOffer';
import OfferManagement from 'pages/offerManagement/offer';

// Hotspot Management
import CreateAndEditHotspot from 'pages/hotSpotManagement/createAndEditHotspot';
import HotspotManagement from 'pages/hotSpotManagement/hotspot';

// Affinity Management
import AffinityActions from 'pages/affinityScoreManagement/affinityScores';
import AffinityActionAddAndEdit from 'pages/affinityScoreManagement/createAndEditActions';

// Religion and cast Management
import ReligionManagement from 'pages/religion&castManagement/religion';
import CastManagement from 'pages/religion&castManagement/cast';

// Auth and UnAuth
import TextEditor from "components/NEW/ui/textEditor";

import { AFFILIATE_COUPONS, AFFINITY_SCORE, BLOG, EVENTS, HOTSPOT,OFFER, RELIGION_AND_CAST, SIDEBAR, USERS } from "./routes";
import { authenticationProps, LAYOUT_TYPES } from "constant/routeHandler";

export const Links = [    
    // ============ NEW VERSION  ============
    {
        name: "Login",
        path: "/login",
        auth: authenticationProps.UNAUTH,
        layoutType: LAYOUT_TYPES.NONE,
        element: <Login/>,
        showInNavigation: true,
    },
    //  ========== Home ==========
    {
        name: "OverView",
        path: SIDEBAR.HOME_OVERVIEW,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <OverView />,
        showInNavigation: true,
    },
   
    // Event Management
    {
        name: "Display all the Events",
        path: SIDEBAR.EVENTS,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <Events/>,
        showInNavigation: false,
    },
    {
        name: "Display all the Events for Comment Management",
        path: SIDEBAR.EVENTS_COMMENTS,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <EventComment/>,
        showInNavigation: false,
    },
    {
        name: "Create and Edit Event Basic Details",
        path: `${EVENTS.CREATE_AND_EDIT_EVENT_BASIC}/:type/:id?`,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <CreateAndEditEventBasicDetails />,
        showInNavigation: false,
    },
    {
        name: "Create and Edit Event Advance Details",
        path: `${EVENTS.CREATE_AND_EDIT_EVENT_ADVANCE}/:type/:id?`,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <CreateAndEditEventAdvanceDetails />,
        showInNavigation: false,
    },
    {
        name: "View Event",
        path: `${EVENTS.VIEW_EVENT}/:slug/:comment`,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.NONE,
        element: <EventPreview/>,
        showInNavigation: false,
    },
    {
        name: "Enable Event Connection",
        path: SIDEBAR.EVENTS_CONNECTIONS,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <EventConnection/>,
        showInNavigation: false,
    },
    {
        name: "View Event Participants",
        path: `${EVENTS.VIEW_EVENT_PARTICIPANT}/:id`,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <EventParticipants />,
        showInNavigation: false,
    },
    {
        name: "View Event Participants",
        path: `${EVENTS.ELIGIBLE_PARTICIPANTS}/:id`,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <EventEligibleParticipants />,
        showInNavigation: false,
    },
    {
        name: "Create Event Category",
        path: SIDEBAR.EVENTS_CATEGORY,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <EventCategory/>,
        showInNavigation: false,
    },
    {
        name: "Create Event Location",
        path: SIDEBAR.EVENTS_LOCATION,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <EventLocation />,
        showInNavigation: false,
    },
    {
        name: "Create Event Tags",
        path: SIDEBAR.EVENTS_TAG,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <EventTag/>,
        showInNavigation: false,
    },
    {
        name: "Create Event Hosts",
        path: SIDEBAR.EVENTS_HOST,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <EventHosts/>,
        showInNavigation: false,
    },
    //  ========== USER ==========
    {
        name: "View All Users",
        path: SIDEBAR.VIEW_USERS,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <ViewAllUsers />,
        showInNavigation: false,
    },
    {
        name: "Edit Users Details",
        path: USERS.EDIT_USER_DETAILS,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.NONE,
        element: <EditUserDetails />,
        showInNavigation: false,
    },
    {
        name: "Deleted Users Details",
        path: USERS.DELETED_USERS,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <DeletedUsers />,
        showInNavigation: false,
    },
    {
        name: "Create New User",
        path: USERS.CREATE_USERS,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.NONE,
        element: <CreateUser />,
        showInNavigation: false,
    },
    
    //  ========== PLAN AND LIMITATION ==========
    {
        name: "View and Edit Plans",
        path: SIDEBAR.PLANS,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <Plans/>,
        showInNavigation: false,
    },
    {
        name: "View and Edit Plan Category",
        path: SIDEBAR.PLAN_CATEGORY,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <PlanCategory />,
        showInNavigation: false,
    },
    {
        name: "View and Edit Plan Coins",
        path: SIDEBAR.PLAN_COIN,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <PlanCoins />,
        showInNavigation: false,
    },
    {
        name: "View and Edit Plan Limitations",
        path: SIDEBAR.PLAN_LIMITATION,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <PlanLimitation />,
        showInNavigation: false,
    },    
    //  ========== COUPONS MANAGEMENT ==========
    {
        name: "View and Edit Coupons",
        path: SIDEBAR.COUPON,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <Coupons/>,
        showInNavigation: false,
    },    
    //  ========== Notification MANAGEMENT ==========
    {
        name: "Notification",
        path: SIDEBAR.NOTIFICATION,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <Notification />,
        showInNavigation: false,
    },        
    {
        name: "Notification History",
        path: SIDEBAR.NOTIFICATION_HISTORY,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <NotificationHistory />,
        showInNavigation: false,
    },        
    //  ========== Account Verification MANAGEMENT ==========
    {
        name: "User Account Verification",
        path: SIDEBAR.VERIFICATION,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <Verification />,
        showInNavigation: false,
    },    
    //  ========== AppUpdate MANAGEMENT ==========
    {
        name: "AppUpdate version update",
        path: SIDEBAR.APP_UPDATE,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <AppUpdate />,
        showInNavigation: false,
    },
    //  ========== Blog MANAGEMENT ==========
    {
        name: "Blog",
        path: SIDEBAR.BLOG,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <Blogs/>,
        showInNavigation: false,
    },    
    {
        name: "Create Blog",
        path: BLOG.CREATE_BLOG,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.NONE,
        element: <CreateBlog />,
        showInNavigation: false,
    },    
    {
        name: "View Blog",
        path: BLOG.VIEW_BLOG,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.NONE,
        element: <ViewBlog />,
        showInNavigation: false,
    },    
    {
        name: "AppUpdate version update",
        path: '/textediter',
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.NONE,
        element: <TextEditor />,
        showInNavigation: false,
    },    
    //  ========== AFFILIATE REFERRAL MANAGEMENT ==========  
    {
        name: "View  and edit affliate coupons",
        path: SIDEBAR.AFFILIATE_COUPONS,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <AffiliateCoupons />,
        showInNavigation: false,
    }, 
    {
        name: "View and track affliate coupons",
        path: AFFILIATE_COUPONS.TRACK_AFFILIATE_COUPON,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <TrackAffiliateCoupons />,
        showInNavigation: false,
    },
     //  ========== OFFER MANAGEMENT ==========  
    {
        name: "Offer Table",
        path: SIDEBAR.OFFER,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <OfferManagement />,
        showInNavigation: false,
    }, 
    {
        name: "Create and edit Offer",
        path: OFFER.CREATE_OFFER,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.NONE,
        element: <CreateOffer />,
        showInNavigation: false,
    }, 
     //  ========== Hotspot MANAGEMENT ==========  
    {
        name: "Hotspot Table",
        path: HOTSPOT.HOTSPOT,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <HotspotManagement />,
        showInNavigation: false,
    }, 
    {
        name: "Create and edit Hotspot",
        path: HOTSPOT.CREATE_HOTSPOT,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.NONE,
        element: <CreateAndEditHotspot />,
        showInNavigation: false,
    }, 
     //  ========== Affinity MANAGEMENT ==========  
    {
        name: "Affinity Actions Table",
        path: AFFINITY_SCORE.AFFINITY_SCORE,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <AffinityActions />,
        showInNavigation: false,
    }, 
    {
        name: "Create and Edit Affinity Actions",
        path: `${AFFINITY_SCORE.CREATE_AFFINITY_SCORE}/:id?`,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <AffinityActionAddAndEdit />,
        showInNavigation: false,
    }, 
     //  ========== Religion and Cast MANAGEMENT ==========  
    {
        name: "Religion Management",
        path: RELIGION_AND_CAST.RELIGION,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <ReligionManagement/>,
        showInNavigation: false,
    }, 
    {
        name: "Cast Management",
        path: RELIGION_AND_CAST.CAST,
        auth: authenticationProps.AUTH,
        layoutType: LAYOUT_TYPES.MAIN,
        element: <CastManagement />,
        showInNavigation: false,
    }, 
    {
        name: "Error404",
        path: "*",
        element: <Error404 />,
        showInNavigation: false,
    },
];