import { createNewPlanCoin, getAllPlansCoins, updatePlanCoin } from 'apis/plans'
import { ManagementSecMainHeader, Table } from 'components'
import React, { Suspense, lazy, useEffect, useState } from 'react'
import { toast } from 'react-toastify'


const CreateAndEditPlanCoins = lazy(() => import("components").then(module => ({ default: module.CreateAndEditPlanCoins })))


function Index() {

    const [planCoin, setPlansCoin] = useState()
    const [selectedPlanCoin, setSelectedPlanCoin] = useState()
    const [showAddAndEditCoin, setShowAddAndEditCoin] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    const fetchAllPlansCoins = async (page) => {
        const res = await getAllPlansCoins(page)
        setPlansCoin(res?.data?.actions)
        const data = res?.data?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }

    useEffect(() => {
        fetchAllPlansCoins(1)
    }, [])

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Name', accessor: 'actionName' },
        { Header: 'Points', accessor: 'points' },
        { Header: 'Type', accessor: 'type' },
        { Header: 'Message', accessor: 'message' },
        { Header: 'Plan Category', accessor: 'plan_category.name' },
        { Header: 'Expiry Days', accessor: 'expiryDays' },

        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <span className={`text-white p-2 rounded-3xl ${value ? 'bg-green-500' : 'bg-brandRed'}`}>
                    {value ? 'active' : 'inactive'}
                </span>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='p-2 mr-1 text-white bg-blue-500 rounded-lg'>Edit</button>
                </div>
            )
        }

    ];


    const handleEdit = (row) => {
        setSelectedPlanCoin(row?.original)
        setShowAddAndEditCoin(true)
        setIsEdit(true)
    }

    const handleSubmit = async (value) => {
        const { id, ...data } = value
        let res
        if (isEdit) {
            res = await updatePlanCoin(id, data)
        } else {
            res = await createNewPlanCoin(value)
        }
        if (res?.success) {
            toast(`${isEdit ? 'updated' : 'Created'} Plan Coin`)
            setShowAddAndEditCoin(false)
            setIsEdit(false)
            fetchAllPlansCoins(pageNation.currentPage)
        }
    }

    const handleCancel = () => {
        setIsEdit(false)
        setSelectedPlanCoin('')
        setShowAddAndEditCoin(false)
    }

    return (
        <div className='flex flex-col h-screen'>

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Plan Coins"}
                        subHeading={"Manage your events effectively"}
                        btnText={"Create Coin"}
                        handleBtn={() => setShowAddAndEditCoin(true)} />
                </div>

                {/* Table */}
                {showAddAndEditCoin ?
                    <div className="w-[98%] flex items-center justify-center">
                        <Suspense fallback={<div>loading...</div>} >
                            <CreateAndEditPlanCoins data={selectedPlanCoin} handleCancel={handleCancel} handleSubmit={(value) => handleSubmit(value)} isEdit={isEdit} />
                        </Suspense>
                    </div>
                    :
                    <div className="w-full p-8">
                        {planCoin &&
                            <Table columns={columns} data={planCoin} pageNation={pageNation} handlePageNation={(value) => fetchAllPlansCoins(value)} />
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Index