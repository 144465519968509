import React, { useEffect, useRef } from 'react';
import { formatDateToDDMMYY } from 'shared/dates';
import { getTheApp } from 'utils/getTheApp';

function ArticleContent({ blogData }) {
    let headingCounter = 0;
    let imageAltCounter = 0;
    const contentRef = useRef(null);

    const insertAddIntoContent = (content) => {
        if (!content) return '';

        const adHtml = blogData?.advertisement_center ? `
            <div style="text-align: center; margin: 20px 0;">
                <img class="ad-image w-full h-auto cursor-pointer" src="${blogData.advertisement_center.image_url}" alt="${blogData.advertisement_center.alt_text || 'Advertisement'}" />
            </div>
        ` : '';

        if (content.includes("center_advertisement")) {
            return content.replace("center_advertisement", adHtml);
        } else {
            return `${content}${adHtml}`;
        }
    };

    const handleOnClickImage = (value) => {
        if (value === 'app_link') {
            getTheApp()
        } else {
            window.open(value, "_blank");
        }
    };

    const styledHtmlContent = insertAddIntoContent(blogData?.blog_content)
        .replace(/<h1>/g, '<h1 style="font-size: 2.5rem;">')
        .replace(/<h2>/g, () => {
            const id = `heading-${headingCounter++}`;
            return `<h2 id="${id}" style="font-size: 2rem;">`;
        })
        .replace(/<h3>/g, '<h3 style="font-size: 1.75rem;">')
        .replace(/<h4>/g, '<h4 style="font-size: 1.5rem;">')
        .replace(/<h5>/g, '<h5 style="font-size: 1.25rem;">')
        .replace(/<h6>/g, '<h6 style="font-size: 1rem;">')
        .replace(/<a /g, '<a style="color: blue; text-decoration: underline;" ')
        .replace(/<ul>/g, '<ul style="list-style-type: disc; margin-left: 20px;">')
        .replace(/<li>/g, '<li style="margin-bottom: 10px;">')
        .replace(/<img(?![^>]*class="ad-image")(?![^>]*alt=["'][^"']*["'])/g, () => {
            const altText = blogData.blog_content_image_alts?.[imageAltCounter] || 'Couple Squad';
            imageAltCounter++;
            return `<img alt="${altText}"`;
        });


    useEffect(() => {
        // Attach the click event listener to the dynamically added image
        const container = contentRef.current;
        if (container) {
            const adImage = container.querySelector('.ad-image');
            if (adImage) {
                adImage.addEventListener('click', () => handleOnClickImage(blogData.advertisement_center.link));
            }
        }

        // Cleanup the event listener
        return () => {
            if (contentRef.current) {
                const adImage = contentRef.current.querySelector('.ad-image');
                if (adImage) {
                    adImage.removeEventListener('click', handleOnClickImage);
                }
            }
        };
    }, [blogData]);

    return (
        <div className="p-4 text-black">
            <div className="mb-4">
                <h1 className="text-3xl font-bold text-gray-900">{blogData?.title}</h1>
                <p className="text-gray-500">CoupleSquad &bull; {formatDateToDDMMYY(blogData?.updatedAt)}</p>
            </div>

            <div
                ref={contentRef}
                className="mb-4"
                dangerouslySetInnerHTML={{ __html: styledHtmlContent }}
            ></div>
        </div>
    );
}

export default ArticleContent;
